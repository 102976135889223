import * as React from "react"
import { Box } from "theme-ui"
import Header from "./header"

const Layout = ({ children }: any) => {
  return (
    <Box
      sx={{
        maxWidth: "container",
        mx: "auto",
        px: [3, 4],
        fontFamily: "body",
      }}
    >
      <Header />
      <main>{children}</main>
      <Box
        sx={{
          borderTop: "1px solid #f7f7f7",
          pt: 4,
          mt: 4,
          mb: 4,
          color: "gray",
          fontSize: 12,
        }}
      >
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.com">Gatsby</a>
      </Box>
    </Box>
  )
}

export default Layout
