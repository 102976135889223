import React from "react"
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby"

import { Box, Link, Heading, Text } from "theme-ui"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title

  return (
    <Box
      sx={{
        variant: "styles.header",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {/* @ts-ignore */}
        <Link as={GatsbyLink} to="/" sx={{ variant: "styles.header.title" }}>
          <Heading as="h1" variant="styles.header.title">
            {title}
          </Heading>
        </Link>
        {/* <Text sx={{ variant: "styles.header.caption" }}>
          Software Engineer and Architect based in Christchurch, New Zealand.
        </Text> */}
      </Box>
      <Box sx={{ mr: "auto " }} />
      <Link
        as={GatsbyLink}
        // @ts-ignore
        to="/speaking"
        sx={{
          variant: "styles.header.link",
        }}
      >
        Speaking & Conferences
      </Link>
      <Text sx={{ mx: 2, color: "#ccc" }}>&bull;</Text>
      <Link
        href="https://linkedin.com/in/tomhallam"
        sx={{
          variant: "styles.header.link",
        }}
      >
        LinkedIn
      </Link>
      <Link
        href="https://github.com/tomhallam"
        sx={{
          variant: "styles.header.link",
        }}
      >
        GitHub
      </Link>
      <Link
        href="https://www.getrevue.co/profile/tomhallam"
        sx={{
          variant: "styles.header.link",
        }}
      >
        Subscribe
      </Link>
    </Box>
  )
}

export default Header
